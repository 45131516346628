<template>
  <div id="container-history">
    <table id="table-history">
      <thead>
        <tr>
          <th scope="col">Date</th>
          <th scope="col">Case type</th>
          <th scope="col">Case name</th>
          <th scope="col">Action</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="actions in store.history" :key="actions">
          <td>{{ new Date().toLocaleString() }}</td>
          <td>{{ actions.type }}</td>
          <td>{{ actions.name }}</td>
          <td>{{ getActionText(actions) }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { RefreshStore } from "../stores/refresh";

export default {
  mounted() {
    const store = RefreshStore();
    console.log(store.history);
  },
  setup() {
    const store = RefreshStore();
    return { store };
  },
  methods: {
    getActionText: function (actions) {
      var text = "";

      switch (actions.action_name[0]) {
        case "PAY":
          text += "You paid " + actions.price_for_each;
          // document.getElementById("table-history").style.width = "10%";
          break;
        case "BUY":
          text += "You bought for " + actions.action_price[0];
          break;
        case "SKIP":
          text += "You skipped";
          break;
        case "TEMPLE":
          text += "You earned " + actions.action_price[0];
          break;
        case "WHEEL":
          text += "You spinned the wheel";
          break;
        case "RETRY":
          text += "You retried to spin the wheel";
          break;
        case "CONTRIBS":
          text += "You contributed to giveaway with " + actions.action_price[0];
          break;
        case "WIN":
          text += "You winned " + actions.action_price[0] + " by spinning the wheel";
          break;
        case "lose": // TODO
          text += "You lost " + actions.action_price[0] + " by spinning the wheel";
          break;
        case "NEVERLUCKY":
          text += "Neverlucky";
          break;
        case "JAIL":
          text += "You were in jail";
          break;
        default:
          text += "Not implemented";
      }
      return text;
    },
  },
};
</script>

<style scoped>
#container-history {
  width: 70%;
  margin-left: 15%;
  margin-top: 2vh;
  margin-bottom: 2vh;
  box-shadow: inset 0 0 5px black, 0 0 5px black;
  font-size: 1.2em;
}

#table-history {
  width: 100%;
}

/* table th {
  border-radius: 50% 5% 0% 0%;
} */

table td {
  padding-top: 12px;
  padding-bottom: 12px;
}

table tr:hover td{
  background-color: #222226;
}


table tr:nth-child(even) td {
  border-spacing: 0 10px;
}

table th {
  background-color: black;
  border: 10%;
  height: 3em;
}
</style>