<template>
  <Refresh ref="refresh" />
  <Login v-if="logged == false" />
  <div v-else-if="logged == true">
    <Refresh ref="refresh" />
    <Header />
    <Board v-if="page == 1 && dataReady == true" />
    <History v-if="page == 2" />
    <Scoreboard v-if="page == 3" />
  </div>
</template>

<script>
import Login from "./components/Login.vue";
import Refresh from "./components/Refresh.vue";
import Board from "./components/Board.vue";
import Header from "./components/Header.vue";
import History from "./components/History.vue";
import Scoreboard from "./components/Leaderboard.vue";

export default {
  name: "App",
  data() {
    return {
      logged: false,
      showDices: false,
      name: null,
      page: 1,
      dataReady: false,
    };
  },
  components: {
    Login,
    Refresh,
    Board,
    Header,
    History,
    Scoreboard,
  },
  mounted() {},
  methods: {},
};
</script>

<style>
html {
  height: 100%;
  background-color: rgb(52, 51, 53);
}
#app {
  font-family: Akshar, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  background-color: rgb(52, 51, 53);
  color: white;
  height: 100% ;
  z-index: 1;
}

body {
  margin: 0 !important;
  background-color: rgb(52, 51, 53);
  color: white;
  height: 100%;
}

* {
  /* background-color: rgb(52, 51, 53); */
  color: white;
}

#balance {
  text-align: left;
}
</style>
