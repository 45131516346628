import { defineStore } from 'pinia';
import { ApiStore } from './api';

export const LoginStore = defineStore('login', {

    actions: {
        async etablishConnection(key, name) {
            var apiStore = ApiStore();
            try {
                var api = apiStore.createApi(key, name);
            }
            catch (error) {
                console.log("Error :", error);
                return null;
            }
            // try {
            //     await api.transact(
            //         {
            //             actions: [
            //                 {
            //                     account: "daoventuresc",
            //                     name: "login",
            //                     authorization: [
            //                         {
            //                             actor: name,
            //                             permission: "active",
            //                         },
            //                     ],
            //                     data: { owner: name },
            //                 },
            //             ],
            //         },
            //         {
            //             blocksBehind: 3,
            //             expireSeconds: 30,
            //         }
            //     );
                apiStore.setApi(api);
                return api;
            // } catch (error) {
            //     console.log("Error :", error);
                
            // }
            // return null;
        },
    }
});
