<template>
  <div id="navbar">
    <div id="name">{{ store.getUsername + " : " + store.getBalance }}</div>
    <button @click="this.$root.page = 1">Home</button>
    <button @click="changePage">History</button>
    <button @click="this.$root.page = 3">Leaderboard</button>
  </div>
</template>

<script>
import { RefreshStore } from "../stores/refresh";

export default {
  name: "Header",
  setup() {
    const store = RefreshStore();
    return { store };
  },
  methods: {
    changePage: async function () {
      if (this.$root.page == 2) return;

      this.$root.page = 2;

      await this.$root.$refs.refresh.getActionsHistory();
    },
  },
};
</script>

<style scoped>
body {
  font-family: Avenir, Helvetica;
  margin: 0 !important;
}

#navbar {
  color: white !important;
  font-size: 99%;
  background-color: rgba(12, 6, 6, 0.6);
  min-width: 100%;
  height: 30px;
}

#navbar button {
  text-decoration: none;
  background-color: transparent;
  border: transparent;
  float: left;
  margin-top: 2px;
}

#name {
  background-color: rgba(12, 6, 6, 0.01);
  float: right;
  margin: 5px;
}
</style>
