<template>
  <div></div>
</template>

<script>
import { ApiStore } from "./../stores/api";
import { RefreshStore } from "./../stores/refresh";

export default {
  name: "Refresh",
  data() {
    return {
      token: null,
      api: null,
      rpc: null,
      refreshStore: null,
    };
  },
  mounted() {
    const api = ApiStore();

    this.api = api.getApi();
    this.rpc = api.getRPC();
    this.refreshStore = RefreshStore();
    if (this.$root.logged == true) {
      this.launchEverything();
      this.launchRefresh();
    }
  },
  methods: {
    getAcount: async function () {
      this.rpc
        .get_table_rows({
          json: true,
          code: "daoventuresc",
          scope: "daoventuresc",
          table: "accounts",
          lower_bound: this.$root.name,
          upper_bound: this.$root.name,
          limit: 1,
        })
        .then((result) => {
          this.refreshStore.setUser(result.rows[0]);
          console.log(result.rows[0])
        });
    },
    getCurrentAction: async function () {
      this.rpc
        .get_table_rows({
          json: true,
          code: "daoventuresc",
          scope: this.$root.name,
          table: "uactions",
          lower_bound: this.refreshStore.getActionID,
          upper_bound: this.refreshStore.getActionID,
          limit: 1,
        })
        .then((result) => {
          // console.log(result);
          this.refreshStore.setActionFromUser(result.rows[0]);
        });
    },
    getCurrentCard: async function () {
      this.rpc
        .get_table_rows({
          json: true,
          code: "daoventuresc",
          scope: "daoventuresc",
          table: "map",
          lower_bound: this.refreshStore.getPosition,
          upper_bound: this.refreshStore.getPosition,
          limit: 1,
        })
        .then((result) => {
          // console.log(result);
          this.refreshStore.setCard(result.rows[0]);
          this.getCurrentCardInfo(
            result.rows[0].case_type,
            result.rows[0].type_id
          );
        });
    },
    getCurrentCardInfo: async function (name, id) {
      // let name = this.refreshStore.getCardType;
      if (
        name == "lands" ||
        name == "buildings" ||
        name == "energies" ||
        name == "boosts" ||
        name == "temples" ||
        name == "tools"
      ) {
        this.rpc
          .get_table_rows({
            json: true,
            code: "daoventuresc",
            scope: "daoventuresc",
            table: name,
            lower_bound: id,
            upper_bound: id,
            limit: 1,
          })
          .then((result) => {
            this.refreshStore.setCardInfo(result.rows[0]);
          });
      } else {
        this.refreshStore.setCardInfo({ name: "Not implemented" });
      }
    },
    // Get info from uactions with user specified
    getActionsHistory: async function () {
      const self = this;

      this.refreshStore.resetActions();

      // Get all actions from user
      this.rpc
        .get_table_rows({
          json: true,
          code: "daoventuresc",
          scope: this.$root.name,
          table: "uactions",
          key_type: `name`,
          reverse: true,
          index_position: 2,
          upper_bound: this.$root.name,
          lower_bound: this.$root.name,
          limit: 1000,
        })
        .then((uactions) => {
          const self1 = self;

          // Get entire map info
          self1.rpc
            .get_table_rows({
              json: true,
              code: "daoventuresc",
              scope: "daoventuresc",
              table: "map",
              limit: 1000,
            })
            .then((result) => {
              const self2 = self1;

              // Iterate on each actions to get case name instead of id case
              for (let i = 0; i < uactions.rows.length; i++) {
                var case_info = result.rows.find(
                  (map) => uactions.rows[i].map_id == map.id
                );
                if (
                  case_info.case_type == "lands" ||
                  case_info.case_type == "buildings" ||
                  case_info.case_type == "energies" ||
                  case_info.case_type == "boosts" ||
                  case_info.case_type == "temples" ||
                  case_info.case_type == "tools"
                ) {
                  // Get case info
                  self2.rpc
                    .get_table_rows({
                      json: true,
                      code: "daoventuresc",
                      scope: "daoventuresc",
                      table: case_info.case_type,
                      lower_bound: case_info.type_id,
                      upper_bound: case_info.type_id,
                    })
                    .then((map_info) => {
                      // Fill action with correct name
                      uactions.rows[i].name = map_info.rows[0].name;
                      uactions.rows[i].type = map_info.rows[0].type;
                      self2.refreshStore.setActionsHistory(uactions.rows[i]);
                    });
                } else {
                  uactions.rows[i].name = case_info.case_type.charAt(0).toUpperCase() + case_info.case_type.slice(1);
                 
                  self1.refreshStore.setActionsHistory(uactions.rows[i]);
                }
              }
            });
        });
    },
    // Get all users and sort them by DAOV number
    getUsers: function () {
      this.rpc
        .get_table_rows({
          json: true,
          code: "daoventuresc",
          scope: "daoventuresc",
          table: "accounts",
          limit: 10000,
        })
        .then((users) => {
          users.rows.sort(function (a, b) {
            if (parseInt(a.balances[0]) < parseInt(b.balances[0])) return 1;
            else if (parseInt(a.balances[0]) == parseInt(b.balances[0]))
              return 0;
            else return -1;
          });

          this.refreshStore.setUsers(users.rows);
        });
    },
    // Get map with all case info
    getMap: async function () {
      const self = this;

      this.refreshStore.resetMap();

      this.rpc
        .get_table_rows({
          json: true,
          code: "daoventuresc",
          scope: "daoventuresc",
          table: "map",
          limit: 1000,
        })
        .then((map) => {
          const self1 = self;

          for (let i = 0; i < map.rows.length; i++) {
            if (
              map.rows[i].case_type == "lands" ||
              map.rows[i].case_type == "buildings" ||
              map.rows[i].case_type == "energies" ||
              map.rows[i].case_type == "boosts" ||
              map.rows[i].case_type == "temples" ||
              map.rows[i].case_type == "tools"
            ) {
              self1.rpc
                .get_table_rows({
                  json: true,
                  code: "daoventuresc",
                  scope: "daoventuresc",
                  table: map.rows[i].case_type,
                  lower_bound: map.rows[i].type_id,
                  upper_bound: map.rows[i].type_id,
                })
                .then((case_info) => {
                  map.rows[i].info = case_info.rows[0];
                
                  self1.refreshStore.setMap(map.rows[i]);
                });
            } else {
              // Capitalize first letter
              let name =  map.rows[i].case_type.charAt(0).toUpperCase() + map.rows[i].case_type.slice(1)

              map.rows[i].info = {name: name};

              self1.refreshStore.setMap(map.rows[i]);
            }
          }
        });
    },
    launchEverything: async function () {
      if (this.$root.page == 2) return;
      // Get account information
      this.getAcount();

      // Get current action from user
      this.getCurrentAction();

      // Get current card info
      this.getCurrentCard();

      // Get more informations from the card
      this.getUsers();

      // this.getMap();
    },
    launchRefresh: function () {
      // Refresh data each 5 seconds
      this.token = setInterval(async () => {
        this.launchEverything();
      }, 10000);
    },
    clearRefresh: function () {
      clearInterval(this.token);
    },
  },
};
</script>
