import { Api, JsonRpc } from "eosjs";
import { JsSignatureProvider } from "eosjs/dist/eosjs-jssig";
import { defineStore } from "pinia";

export const ApiStore = defineStore('api', {
    // Variables
    state: () => ({
        key: '',
        name: '',
        rpc: new JsonRpc("https://testnet.waxsweden.org", { fetch }),
        api: null,
    }),
    actions: {
        // Create an EOSIO API
        createApi(key, name) {
            this.key = key;
            this.name = name;

            // Generate the signature of your account
            const signatureProvider = new JsSignatureProvider([key]);

            const rpc = this.rpc;
            this.api = new Api({
                rpc,
                signatureProvider,
                textDecoder: new TextDecoder(),
                textEncoder: new TextEncoder(),
            });
            return this.api;
        },
        // Get EOSIO API connected to wax testnet
        getApi() {
            return this.api;
        },
        // Get RPC connected to wax testnet
        getRPC() {
            return this.rpc;
        },
        // Setter API after user logt
        setApi(api) {
            this.api = api;
        }
    }
});
