<template>
  <div id="history-container">
    <table class="table table-dark">
      <thead>
        <tr>
          <th scope="col">Position</th>
          <th scope="col">Wallet adress</th>
          <th scope="col">DAOV</th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="(user, index) in store.users" :key="user">
          <td>{{ index + 1 }}</td>
          <td>{{ user.account }}</td>
          <td>{{ user.balances[0] }}</td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import { RefreshStore } from '../stores/refresh';

export default {
  setup() {
    const store = RefreshStore();
    return { store };
  },
};
</script>

<style scoped>
#history-container {
  width: 50%;
  margin-left: 25%;
  margin-top: 2vh;
}


</style>