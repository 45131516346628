<template>
  <!-- <h2>Welcome to DAOVenture!</h2> -->
  <div class="login-form">
  <h3>Login</h3>
  <h5>Just press login if you want to connect with thallardwax1's account</h5>
  <div class="name-form">
  <label class="label-form">Name:</label>
  <b-form-input type="text" v-model="name" placeholder="Enter account name" class="input-form"/>
  </div>
  <div class="name-form">
  <label class="label-form">Private Active Key:</label>
  <b-form-input type="text" v-model="key" placeholder="Enter private key"  class="input-form"/>
  </div>
   <div>
  Keep me logged in: 
  <input type="checkbox"   v-model="saveCreds" class="keep-form" />
  </div>
  <b-button :variant="fail ? 'danger': 'success'" :disabled="!key && !name" @click="openLogin">Login to Testnet<b-spinner small type="grow" v-if="spin" label="Spinning"></b-spinner></b-button>
  
  </div>
</template>
<script>
import { LoginStore } from "./../stores/login";

export default {
  data() {
    return {
      key: "5JVzqKYHXnhRttLpKKWPaL1AxeCb88ELKYCK21wzNUFS2MtXjeR",
      name: "thallardwax1",
      spin: false,
      api: null,
      fail: false,
    };
  },
  mounted () {
    if (localStorage.getItem("name") && localStorage.getItem("key")) {
      this.name = localStorage.getItem("name");
      this.key = localStorage.getItem("key");
      this.openLogin();
    }
  },
  methods: {
    // Call EOS API and connect to testnet
    openLogin: async function () {
      const login = LoginStore();
      this.spin = true;
      this.api = await login.etablishConnection(this.key, this.name);
      this.spin = false;
      if(this.api != null) {
      if (this.saveCreds){
        localStorage.setItem("name", this.name);
        localStorage.setItem("key", this.key);
      }
      this.$root.name = this.name;
      
      await this.$root.$refs.refresh.getMap();
      await new Promise(r => setTimeout(r, 1000));
      this.$root.dataReady = true;
      this.$root.logged = true;
      }
      else {
        this.fail = true;
      }

    },
  },
};
</script>


<style>
.login-form {
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-left: 25%;
  height:100%;
}

.name-form{
  display: inline-block;
  text-align: center;
  margin-top: 10px;
}


.keep-form {
  margin-left: 2%;
}
</style>