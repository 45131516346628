<template>
  <div id="boardgame" v-if="this.$root.dataReady" >
    <div id="container-cards-top">
      <div :class="'card' + getCardPosition(i) + ' ' +  getCorner(i) + ' ' + onCase(i)" v-for="i in range(0,10)" :key="i">
            {{ store.map[i].info.name }}
         <img class="image" :src="getCardImage(store.map[i].case_type)"/>
      </div>
    </div>
    <div id="container-cards-side">
      <div id="container-cards-left">
        <div :class="'card' + getCardPosition(i) + ' ' +  getCorner(i) + ' ' + onCase(i)" v-for="i in range(31, 39)" :key="i">
            {{ store.map[i].info.name }}
           <img class="image" :src="getCardImage(store.map[i].case_type)"/>
        </div>
      </div>
       <div id="choice-container">
        <div id="choice-container-buttons" v-if="store.getActionID != 0">
            <b-button class="choice-button"  v-for="(action, idx) in store.getActionChoices" :key="action"  :variant="this.variant[action]" @click="makeChoice(idx)" >{{ action }} {{ (action != "SKIP" || store.getActionPrice[idx] == 0) ? "for " + store.getActionPrice[idx] : "" }} </b-button>
            </div>
            <div id="second-choice-container" v-else>
                <button type="button" class="btn btn-dark" id="roll" v-on:click="rollDice">Roll dices </button>
        
        </div>
        <div id="dices-container" v-if="this.$root.showDices == true">
            <img :src="getDiceImage(0)" width="64" referrerpolicy="no-referrer"/>
            <img :src="getDiceImage(1)" width="64" referrerpolicy="no-referrer"/>
        </div>
        </div>
      <div id="container-cards-right">
        <div :class="'card' + getCardPosition(i) + ' ' +  getCorner(i) + ' ' + onCase(i)" v-for="i in range(11, 19)" :key="i">
            {{ store.map[i].info.name }}
           <img class="image" :src="getCardImage(store.map[i].case_type)"/>
        </div>
      </div>
    </div>
    <div id="container-cards-bottom">
        <div :class="'card' + getCardPosition(i) + ' ' +  getCorner(i) + ' ' + onCase(i)" v-for="i in range(20, 30)" :key="i">
            {{ store.map[i].info.name }}
           <img class="image" :src="getCardImage(store.map[i].case_type)"/>
        </div>
      </div>
  </div>
</template>

<script>
import { RefreshStore } from "../stores/refresh";
import { BoardStore } from "../stores/board";

export default {
  name: "Board",
  data() {

    return {
      variant: {
        BUY: "success",
        SKIP: "secondary",
        PAY: "danger",
        EXIT: "warning",
      },
      map: null,
    
    };
  },
  setup() {
    const store = RefreshStore();
    
    return { store };
  },
  methods: {
    range: function (start, end) {
      return Array(end - start + 1).fill().map((_, idx) => start + idx);
    },
    makeChoice: async function (idx) {
      const board = BoardStore();
      await board.makeChoice(this.$root.name, idx);
      this.$root.$refs.refresh.launchEverything();
    },
    rollDice: async function () {
      const board = BoardStore();
      const result = await board.rollDice(this.$root.name);
      if (result == undefined || result == null) {
        alert("You can't roll dices now, you have to make an action before.");
        return;
      } else {
        this.$root.$refs.refresh.launchEverything();
      }

      this.$root.showDices = true;
    },
    getDiceImage: function (index) {
      const store = RefreshStore();
      const launches = store.getLaunch;

      if (launches && launches != undefined)
        return require("../assets/dice-" + launches[index] + ".png");
    },
    getCardImage: function (card) {
      if (card && card != undefined)
        return require("../assets/" + card + ".png");
    },
    getCardPosition: function (id) {
      if (id >= 0 && id <= 10)
        return "-top";
      else if (id >= 11 && id <= 19) 
        return "-right";
      else if (id >= 20 && id <= 30)
        return "-bottom";
      else 
        return "-left";
    },
    getCorner: function (id) {
      if (id == 0 || id == 10 || id == 30 || id == 20) return " corner";
    },
    onCase: function (id) {
      const store = RefreshStore();

      if (id == store.getPosition - 1)
        return " current";
    }
  },
};
</script>

<style scoped>
body {
  height: 100%;
  background-color: black !important;
}

#boardgame {
  margin-top: 0.5%;
  justify-content: center;
  height: 100%;
}

#container-cards-top {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

#container-cards-bottom {
  display: flex;
  flex-direction: row-reverse;
  justify-content: center;
}

#container-cards-side {
  display: flex;
  flex-direction: row;
}

#choice-container {
  margin-left: -19%;
  width: 20%;
  justify-content: center;
}

#choice-container-buttons {
  display: flex;
  flex-direction: row;
  justify-content: center;
  text-align: center;
}

#second-choice-container button {
  width: auto !important;
  font-size: 1.2em;
}

#container-cards-left {
  display: flex;
  flex-direction: column-reverse;
  justify-content: center;
  margin-left: 27%;
  flex: 1;
}

#container-cards-right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  margin-left: 8%;
  flex: 1;
  z-index: 1;
}

.card-top {
  border: 1px solid white !important;
  width: 4vw;
  height: 5vw;
  display: flex;
  flex-direction: column;
  justify-content: center;
  font-size: 0.8em;
}

.card-right {
  border: 1px solid white !important;
  width: 5vw;
  height: 4vw;
  justify-content: center;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
}

.card-bottom {
  display: flex;
  flex-direction: column;
  border: 1px solid white !important;
  width: 4vw;
  height: 5vw;
  justify-content: center;
  font-size: 0.8em;
}

.card-left {
  border: 1px solid white !important;
  width: 5vw;
  height: 4vw;
  justify-content: center;
  font-size: 0.8em;
  display: flex;
  flex-direction: column;
}

.current {
  background-color: red;
}

.card-right img {
  margin-left: 30%;
}

.card-left img {
  margin-left: 30%;
}

.card-bottom img {
  margin-left: 30%;
}


.card-top img {
  margin-left: 30%;
}

.corner {
  width: 5vw;
}

.separator {
  width: 20%;
  margin-left: 40%;
  margin-top: 1%;
}

.choice-button {
  margin: 0.5%;
  width: 40%;
  
  
}

#dices-container img {
    margin: 5px;
  
}

.image {
  width: 45%;
}
</style>