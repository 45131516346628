import { defineStore } from "pinia";
import { ApiStore } from "./../stores/api";

export const RefreshStore = defineStore('refresh', {
    state: () => ({
        api: null,
        rpc: null,
        user: null,
        action: null,
        card: null,
        card_info: null,
        history: [],
        users: null,
        map: [],
    }),
    actions: {
        // Initiliaze API and RPC and start gathering data
        setupStore() {
            const api = ApiStore();

            this.api = api.getApi();
            this.rpc = api.getRPC();
        },
        // Store user information
        setUser(user) {
            this.user = user;
        },
        // Store user's actions
        setActionFromUser(action) {
            this.action = action;
        },
        // Store current card
        setCard(card) {
            this.card = card;
        },
        // Store current card information
        setCardInfo(info) {
            this.card_info = info;
        },
        // Store uactions history's user
        setActionsHistory(history) {
           this.history.push(history);
        },
        // Reset actions history
        resetActions() {
            this.history = [];
        },
        // Store users sorted by DAOV
        setUsers(users) {
            this.users = users;  
        },
        // Store map with complete case info
        setMap(card) {
            this.map.push(card);
            this.map.sort(function (a, b) {
                if (a.id < b.id) 
                    return -1;
                else if (a.id == b.id)
                    return 0;
                else 
                    return 1;
              });
        },
        // Reset map array
        resetMap() {
            this.map = []
        },
    }, 
    getters: {
        getUser() {
            return this.user;
        },
        getUsername() {
            if (this.user)
                return this.user.account;
        },
        getBalance() {
            if (this.user)
                return this.user.balances[0];
        },
        getPosition() {
            if (this.user)
                return this.user.position;
        },
        getLaunch() {
            if (this.user)
                return this.user.last_launch;
        },
        getActionID() {
            if (this.user)
                return this.user.action_id;
        },
        getActionChoices() {
            if (this.action)
                return this.action.action_name;
        },
        getActionPrice() {
            if (this.action)
                return this.action.action_price;
        },
        getCard() {
            if (this.card)
                return this.card;
        },
        getCardName() {
            if (this.card_info)
                return this.card_info.name;
        },
        getCardType() {
            if (this.card)
                return this.card.case_type;
        },
        getCardTypeID() {
            if (this.card)
                return this.card.type_id;
        },
        getHistory() {
            if (this.history)
                return this.history;
        },
        getMap() {
            if (this.map)
                return this.map;
        },
       
    },
});