import { defineStore } from 'pinia';
import { ApiStore } from './api';

export const BoardStore = defineStore('board', {
    state: () => ({
        api: null,
    }),
    actions: {
        // Refresh the API value 
        initializeBoard() {
            const store = ApiStore();

            this.api = store.getApi();
        },
        async makeChoice(name, choice) {
            this.initializeBoard();
            var result = null;
            try {
                result = await this.api.transact(
                    {
                        actions: [
                            {
                                account: "daoventuresc",
                                name: "doaction",
                                authorization: [
                                    {
                                        actor: name,
                                        permission: "active",
                                    },
                                ],
                                data: { owner: name, choice: choice },
                            },
                        ],
                    },
                    {
                        blocksBehind: 3,
                        expireSeconds: 30,
                    }
                );
                // console.log(result);
                // return result;
            } catch (error) {
                console.log("Error :", error);
            }
            return result;
        },
        // Call the SC and get the number
        async rollDice(name) {
            this.initializeBoard();
         
            // Call launch function from smart contract
            try {
                const result = await this.api.transact(
                    {
                        actions: [
                            {
                                account: "daoventuresc",
                                name: "launch",
                                authorization: [
                                    {
                                        actor: name,
                                        permission: "active",
                                    },
                                ],
                                data: { owner: name },
                            },
                        ],
                    },
                    {
                        blocksBehind: 3,
                        expireSeconds: 30,
                    }
                );
                return result;
            } catch (error) {
                console.log("Error :", error);
                return 1;
            }
            // return null;
        }
    }
});